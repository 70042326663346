import { ArrowRightIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { GlobalContext } from "../../../GlobalContext";

function ChooseTemplate({
	data,
	studentName,
	setStudentName,
	setLimit,
	getSheet,
	setOpenTemplates,
	api_route,
	setCountReports,
}) {
	const { chosenTemplate, setChosenTemplate, setReportSheetData } =
		useContext(GlobalContext);
	useEffect(() => {
		const closeReportCardPreview = () => {
			setReportSheetData([]);
		};
		window.addEventListener("keyup", function (event) {
			if (event.key == "Escape") closeReportCardPreview();
		});
	}, []);
	return (
		<>
			{/* {chosenTemplate && (
				<img
					className="w-[200px] mx-auto mb-5"
					src={`${api_route}/${chosenTemplate.screenshot}`}
				/>
			)} */}
			<p className="font-bold text-lg mb-3 text-gray-400">
				Choose Template below
			</p>

			<label
				className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative my-2`}
			>
				<input
					type="checkbox"
					name="assessmentId"
					value={"tmp1"}
					{...(chosenTemplate == "tmp1" && {
						checked: true,
					})}
					{...(chosenTemplate != "tmp1" && {
						checked: false,
					})}
					onChange={(event) => {
						console.log(event.target.value);
						setChosenTemplate("tmp1");
					}}
				/>
				<p className="mr-auto flex-shrink-0">One Assessment</p>
				<span className="flex-shrink-0">
					<CheckIcon className="w-full text-white" />
				</span>
				<div className="absolute inset-0"></div>
			</label>
			<label
				className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative my-2`}
			>
				<input
					type="checkbox"
					name="assessmentId"
					value={"tmp2"}
					{...(chosenTemplate == "tmp2" && {
						checked: true,
					})}
					onChange={(event) => {
						setChosenTemplate("tmp2");
					}}
				/>
				<p className="mr-auto flex-shrink-0">Two Assessments</p>
				<span className="flex-shrink-0">
					<CheckIcon className="w-full text-white" />
				</span>
				<div className="absolute inset-0"></div>
			</label>
			<label
				className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative my-2`}
			>
				<input
					type="checkbox"
					name="assessmentId"
					value={"continuous"}
					{...(chosenTemplate == "continuous" && {
						checked: true,
					})}
					onChange={(event) => {
						setChosenTemplate("continuous-linear");
					}}
				/>
				<p className="mr-auto flex-shrink-0">
					Continous assessment (Linear)
				</p>
				<span className="flex-shrink-0">
					<CheckIcon className="w-full text-white" />
				</span>
				<div className="absolute inset-0"></div>
			</label>
			{!chosenTemplate && (
				<div className="flex flex-col justify-center items-center">
					<button
						onClick={() => {
							setOpenTemplates(true);
						}}
						className="bg-[#008] text-[#fff] py-2 px-4 rounded-[5px] hover:scale-[1.1]"
					>
						Choose template
					</button>
					<p className="text-center mt-2">
						Choose a template that you will use for printing these
						templates.
					</p>
				</div>
			)}
		</>
	);
}

export default ChooseTemplate;
