import React from "react";

function GradingTable({ grading }) {
	return (
		<table className="black w-full">
			<tr>
				<th colSpan={grading.length} className="p-2 bg-[#f2f2f2]">
					Grading
				</th>
			</tr>
			<tr>
				{grading.map((gr) => (
					<th className="p-2" key={`tp-${gr.id}`}>
						{gr.min}-{gr.max}
					</th>
				))}
			</tr>
			<tr>
				{grading.map((gr) => (
					<th className="p-2" key={`bt-${gr.id}`}>
						{gr.symbol}
					</th>
				))}
			</tr>
		</table>
	);
}

export default GradingTable;
