import {
	ChevronDoubleLeftIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ClipboardCopyIcon,
	ClipboardIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import {
	failedAlert,
	loader,
	successAlert,
	warningAlert,
} from "./vanilla/Dialogues";

function StudentDataUploadPreview(props) {
	const {
		uploadedExcelFile,
		showBox,
		setShowBox,
		previewData,
		setPreviewData,
	} = props;
	const { myAxios } = useContext(GlobalContext);
	const [activeSheet, setActiveSheet] = useState(0);
	const [activeSheetData, setActiveSheetData] = useState(null);
	const limit = 80;
	const [pageCount, setPageCount] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	useEffect(() => {
		if (previewData.length > 0) {
			setActiveSheetData(previewData[activeSheet]);
			setTotalPages(
				Math.ceil(previewData[activeSheet].data.length / limit)
			);
		}
	}, [previewData, activeSheet]);
	useEffect(() => {
		setPageCount(totalPages == 0 ? 0 : 1);
	}, [totalPages]);
	if (previewData.length == 0) return <></>;

	const increasePage = (num) => {
		// setPageCount(totalPages == 0 ? 0 : 1);
		if ((num < 0 && pageCount == 1) || totalPages == 0) return;
		if (pageCount == totalPages && num > 0) return;
		setPageCount((prev) => eval(prev + num));
	};

	const row = (activeSheetData) => {
		let init = limit * (pageCount - 1);
		let max = init + limit;
		// console.log(init, max);
		return activeSheetData.data.map((student, index) => {
			if (index >= init && index <= max) {
				console.log(student);
				if (student.length == 5) student.unshift("");
				return (
					<tr
						key={
							"row-student-" +
							student[0] +
							student[1].replace(/ /g, "") +
							student[3].replace(/ /g, "")
						}
					>
						<td className="bg-[#f003]">{student[0]}</td>
						<td>{student[1]}</td>
						<td>{student[2]}</td>
						<td>{student[3]}</td>
						<td>{student[4]}</td>
						<td>{student[5]}</td>
					</tr>
				);
			}
		});
	};

	const saveExcelData = () => {
		console.log(uploadedExcelFile);
		loader(1);
		myAxios
			.post("/students/saveStudentsInExcel", {
				filename: uploadedExcelFile,
			})
			.then((response) => {
				loader(0);
				let d = response.data;
				if (d.msg == "ok") {
					successAlert("Students records have been saved.");
					setPreviewData([]);
					setShowBox(false);
				} else warningAlert(d.msg);
			})
			.catch((error) => {
				loader(0);
				failedAlert("There was an error. Please click save again.");
			});
	};

	return (
		<div
			className={`fixed h-screen w-full top-0 left-0 overflow-hidden`}
			style={{
				backgroundColor: "rgba(0,0,0,0.6)",
				transform: showBox ? "scale(1)" : "scale(0)",
				opacity: showBox ? "1" : "0",
				transition: "0.1s all",
			}}
		>
			<div className="h-full overflow-hidden wd mx-auto flex flex-col">
				<div
					className="bg-white m-6 flex flex-col flex-grow rounded-sm overflow-hidden"
					style={{ boxShadow: "0 0 15px #fff" }}
				>
					<p
						className="px-5 py-2 font-semibold flex items-center"
						style={{ borderBottom: "1px solid #eeeeee" }}
					>
						<span className="text-red-500 mr-1">
							{activeSheetData?.class}
						</span>
						DATA PREVIEW{" "}
						<span className="ml-auto font-normal text-[#777]">
							Please look through and confirm by pressing the save
							button
						</span>
					</p>
					<div className="overflow-y-scroll flex-grow">
						<table className="table-light w-full text-[12px]">
							<thead className="sticky top-0">
								<tr>
									<th
										colSpan={6}
										className="text-center text-lg font-bold"
									>
										{activeSheetData?.class}{" "}
										{activeSheetData?.stream} -{" "}
										<span className="text-red-500 mx-1">
											{activeSheetData?.data.length}
										</span>
										students
									</th>
								</tr>
								<tr className="thead">
									<th>ID</th>
									<th>FIRST NAME</th>
									<th>OTHER NAMES</th>
									<th>FULL NAME</th>
									<th>DATE OF BIRTH</th>
									<th>GENDER</th>
								</tr>
							</thead>
							<tbody>
								{activeSheetData && row(activeSheetData)}
							</tbody>
						</table>
					</div>
					<div
						className="flex flex-col items-center px-5 py-2 select-none"
						style={{
							borderTop: "1px solid #eeeeee",
						}}
					>
						<div className="flex items-center flex-wrap">
							{previewData.map((data, index) => (
								<div
									key={
										"sheet-" +
										data.class +
										data.streamId +
										data.classId
									}
									className={`flex items-center ${
										index === activeSheet
											? "bg-orange-200 font-bold"
											: "bg-gray-200 font-semibold"
									} px-2 py-1 mr-2 rounded-sm text-xs cursor-pointer`}
									onClick={() => {
										setActiveSheet(index);
									}}
								>
									<ClipboardIcon
										className={`w-4 mr-1 ${
											index === activeSheet
												? "text-orange-700"
												: "text-gray-700"
										}`}
									/>
									{data.class}
								</div>
							))}
						</div>
						<div className="flex items-center mt-2">
							<span className="ml-3">Navigation</span>
							<ChevronLeftIcon
								className="w-5 mx-3 cursor-pointer"
								onClick={() => increasePage(-1)}
							/>
							<span>
								{pageCount} / {totalPages}
							</span>
							<ChevronRightIcon
								className="w-5 mx-3 cursor-pointer"
								onClick={() => increasePage(1)}
							/>
							<button
								onClick={() => {
									setPreviewData([]);
									setShowBox(false);
								}}
								className="bg-[#eee] text-black ml-auto mr-4 py-1 px-4 rounded-sm"
							>
								cancel
							</button>
							<button
								onClick={saveExcelData}
								className="bg-blue-800 text-white py-1 px-4 rounded-sm"
							>
								save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentDataUploadPreview;
