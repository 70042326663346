import axios from "axios";
import React, { useEffect, useState } from "react";
import {
	failedAlert,
	loader,
	successAlert,
} from "./components/vanilla/Dialogues";
import { useNavigate } from "react-router-dom";

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
	const [token, setToken] = useState(0);
	const [reportMode, setReportMode] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [schoolData, setSchoolData] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [reportSheetData, setReportSheetData] = useState([]);
	const [chosenTemplate, setChosenTemplate] = useState("");
	const [myAccount, setMyAccount] = useState({});
	const [term, setTerm] = useState({});
	const [termPopup, setTermPopup] = useState(false);
	// const [allYears, setAllYears] = useState([]);
	const [allTerms, setAllTerms] = useState([]);
	const [userData, setUserData] = useState({
		name: "",
		email: "",
		userId: null,
		schoolName: "",
		token: "",
	});
	const [classes, setClasses] = useState([]);
	const [subjects, setSubjects] = useState([]);
	// const api_route = "http://127.0.0.1:4500";
	const api_route = "https://api.schoolware.co";

	useEffect(() => {
		if (token && schoolData) return;
		setToken(localStorage.getItem("token"));
		// console.log("Getting token", localStorage.getItem("token"));
		// if (!token) window.location = "/";
		if (token) {
			console.log("Here comes the data");
		}
	}, []);

	// Axios interceptor
	const myAxios = axios.create({ baseURL: api_route });

	myAxios.interceptors.request.use(
		(config) => {
			config.headers["Authorization"] = `Bearer ${token}`;
			return config;
		},
		(error) => Promise.reject(error)
	);
	// axios.post("./", data, {
	// 	headers: { ["Authorization"]: `Bearer ${token}` },
	// });

	function loadData() {
		if (schoolData) return;
		// alert(token);
		myAxios
			.get("/findMySchool")
			.then((response) => {
				console.log("data =  ", response.data);
				let data = response.data;

				if (!data.term?.term) {
					setTermPopup(true);
				}
				setSchoolData(data);
				setAccounts(data.accounts);
				setMyAccount(data.myAccount);
				setTerm(data.term);
				setAllTerms(data.terms);
				if (!data.term) {
					failedAlert(
						"Please select your current term before you proceed."
					);
				}
				console.log("Requested term = ", response.data.term);
				console.log("My account is = ", response.data.myAccount);
			})
			.catch((error) => {
				console.log(error);
				// alert();
				if (error.response?.status == 401) {
					failedAlert(
						"Unauthorized access. Please login again.",
						() => {
							// loader(1);
							localStorage.removeItem("token");
							window.location = "/";
							// alert(token);
						}
					);
				}
				console.log(error);
			});
	}
	const loadClasses = () => {
		// loader(1);

		myAxios
			.get("/marksheets/classes")
			.then((response) => {
				// loader();
				console.log("classes -- ", response.data);
				setClasses(response.data.data);
			})
			.catch((error) => {
				// loader();
				// failedAlert(`Request failed. Please try again. ${error}`);
			});
	};

	useEffect(() => {
		// if(token == null) setIsLoggedIn();
		if (token) {
			loadData();
			loadClasses();
			// successAlert("The token is here " + token, () => {
			// });
		} else {
			let tk = localStorage.getItem("token");
			setToken(tk);
			// navigate("/home/sm/students", { replace: true });
			// window.location.href = "/home/sm/students";
		}
	}, [token]);

	return (
		<GlobalContext.Provider
			value={{
				isLoggedIn,
				api_route,
				myAxios,
				token,
				myAccount,
				accounts,
				term,
				setToken,
				classes,
				setClasses,
				subjects,
				setSubjects,
				schoolData,
				setSchoolData,
				loadData,
				loadClasses,
				termPopup,
				setTermPopup,
				reportMode,
				setReportMode,
				allTerms,
				reportSheetData,
				setReportSheetData,
				chosenTemplate,
				setChosenTemplate,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export { GlobalContext, GlobalProvider };
